import React, { Suspense, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

import "../component.css";
import "./header.css"

const Header = (props) => {

  const [currentPostion,setCurrentPositon] = useState(0)
  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      setCurrentPositon(window.scrollY)
    })
    
    
  },[])

  return (
    <>
    <Navbar bg="custom" expand="lg" fixed="top" className={currentPostion===0?'backgroundHeaderTransparent':'backgroundHeader'} >
  <Container fluid>
  <img
        src="/sharedRig.png"
        width="80"
        height="80"
        className="d-inline-block align-top"
        alt="SharedRig logo"
      />
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto headerFont">
        <Nav.Link className="linkText" href="/home">Home</Nav.Link>
        <Nav.Link className="linkText" href="/home#about">About</Nav.Link>
        <Nav.Link className="linkText" href="/home#services">Services</Nav.Link>
        <Nav.Link className="linkText" href="/home#faq">FAQ</Nav.Link>
        <Nav.Link className="linkText" href="/home#contact">Contact</Nav.Link>
        <Nav.Link className="linkText" href="/consultation">Consultation</Nav.Link>

        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown> */}
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar></>
  );
};

export default Header;
