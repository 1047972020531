import React, { Suspense, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import "../component.css";
import "./Footer.css"
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";


const Footer = (props) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm();
      const onSubmit = (data) => console.log(data);
  return (
    <>
    <div className="footerContainer">

        <div className="footerNewsletter">
            <h3>Newsletter</h3>
            <p>Signup to know when next machines are available</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Control
                        type="email"
                        placeholder="Enter email"
                        {...register("name")}
                      />
                      <Button className="mt-2" variant="primary" type="submit">
                        Submit
                      </Button>
                </Form>
        </div>
        {/* <div className="footerContact">
            <h3>Contact Us</h3>

        </div> */}
        <div className="footerCopyright">
            <p>© Copyright 2021 SharedRig . All Rights Reseverd</p>

        </div>

    </div>

    </>
  );
};

export default Footer;
