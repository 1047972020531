import React, { Suspense, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaBeer, FaCompressArrowsAlt } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { MdRoomService } from "react-icons/md";
import Accordion from "react-bootstrap/Accordion";
import "../component.css";
import "./consultationPage.css";

import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Footer from "../Footer/Footer";

const ConsultationPage = (props) => {
  const ICON_SIZE = 70;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);

  return (
    <>
      <div
        style={{ backgroundImage: "url('/background.png')" }}
        className=" mainHeaderPadding consultationPageContainer"
      >
        <div className="consultationHeader">
          <div className="boxHeader">
            <h1>Procurment</h1>
            <h1>Services</h1>
            <p>
              Trust SharedRig to procure the hardware critical to the success of
              your Bitcoin mining operation. Your objectives, your budget, our
              expertise.
            </p>
          </div>
        </div>
      </div>
      <div className="consultationPageContainer"></div>

      <div className="consultationPageContainer">
        <div className="procurmentInfo ">
          <h1 style={{ textAlign: "center", padding: "20px" }}>
            Hardware Procurment Made Easy
          </h1>
          <Row>
            <Col>
              <GoChecklist size={ICON_SIZE} style={{ marginBottom: "13px" }} />
              <h4>Define your objectives</h4>
              <p>
                Work directly with industry experts to clearly define your
                budget and capital deployment strategy so you can hit timelines
                to efficiently increase your hashrate under management.
              </p>
            </Col>
            <Col>
              <FaCompressArrowsAlt
                size={ICON_SIZE}
                style={{ marginBottom: "13px" }}
              />
              <h4> Procurement Services</h4>
              <p>
                Our team works to help you acquire ASICs at the best possible
                prices. For larger clients we offer escrow services to
                efficiently deploy capital and lock-in deals with small windows
                of opportunity.
              </p>
            </Col>
            <Col>
              <MdRoomService
                size={ICON_SIZE}
                style={{ marginBottom: "13px" }}
              />
              <h4>Door-to-Door Service</h4>
              <p>
                SharedRig handles all machine testing, packaging, logistics,
                taxes, and associated paperwork to de-stress the last mile of
                the procurement process and get your ASICs to their final
                destination safely and securely.
              </p>
            </Col>
          </Row>
        </div>
        <div className="hrTag">
          <hr />
        </div>
        <div className="form">
          <h1 style={{ textAlign: "center", padding: "20px" }}>Contact Our Procurment Team</h1>
          <p>
            Trust SharedRig to procure the hardware critical to your Bitcoin
            mining operation's success. Your objectives, your budget, our
            expertise.
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="formInput" lg="6">
                {/* register your input into the hook by invoking the "register" function */}
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />
              </Col>
              <Col className="formInput" lg="6">
                {/* include validation with required or other standard HTML validation rules */}
                {/* <input {...register("email", { required: true })} /> */}
                <Form.Control
                  type="email"
                  placeholder=" Email"
                  {...register("name")}
                />
              </Col>
              <Col className="formInput" lg="12">
                {/* include validation with required or other standard HTML validation rules */}
                {/* <input {...register("message", { required: true })} /> */}
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  {...register("name")}
                />
              </Col>

              <Col className="formInput" lg="12">
                {/* include validation with required or other standard HTML validation rules */}
                {/* <input {...register("message", { required: true })} /> */}
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </Col>

              {/* errors will return when field validation fails  */}
              {errors.exampleRequired && <span>This field is required</span>}
              <div>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConsultationPage;
