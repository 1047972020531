import React, { Suspense, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaBeer } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import ProgressBar from 'react-bootstrap/ProgressBar'

import "../component.css";
import "./miningPage.css";

export default function MinerCard() {

  const  [rasiedPercentage,setRasiedPercentage] = useState(10)
  return (
      <>
    <div className="miningCard">
       {/* <h1>tesrt</h1>  */}
       <Col>
      <img src="/s19jPro.png" />
      <h4>Antminer s19j Pro</h4>
      <h6>Hosted in Toronto</h6>
      <ProgressBar now={rasiedPercentage}  label={`${rasiedPercentage}%`}/>
      <Row>
          <Col lg="6">$1000 Rasied</Col>
          <Col lg="6">10%</Col>

          <Col lg="6">Price</Col>
          <Col lg="6">$10,000</Col>

          <Col lg="6">Hashrate</Col>
          <Col lg="6">100 TH/s</Col>

          <Col lg="6">Power</Col>
          <Col lg="6">3500 Watts</Col> 

          <Col lg="6">Cryptocurrency</Col>
          <Col lg="6">Bitcoin (BTC)</Col>


          <Col lg="6">Electricity</Col>
          <Col lg="6">0.06 /kWh</Col>

          <Col lg="6">Average Turnover</Col>
          <Col lg="6">1138.64 /Month</Col>

          <Col lg="6">Online Date</Col>
          <Col lg="6">January</Col>
      </Row>
      </Col>
    </div>
    </>
  );
};