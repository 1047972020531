import React, { Suspense, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaBeer } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import MinerCard from "./MiningCard";
import "../component.css";
import "./miningPage.css";



const MiningPage = (props) => {
  return (
    <>
      <div className=" miningPageContainer">
        <div className="miningHeader">
          <h1>Mining Hardware</h1>
        </div>
        <div className="miningHardwareContainer">
          <Row>
            <Col>
          <MinerCard />
          </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default MiningPage; 
