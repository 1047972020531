import logo from "./logo.svg";
import "./App.css";
import Landingpage from "./component/LandingPage/landingPage";
import MingingPage from "./component/MiningPage/MiningPage";
import ConsultationPage from "./component/consultationPage/ConsultationPage";
import Header from "./component/Header/header";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {/* <Landingpage/> */}

        <Switch>
          <Route path="/home">
            <Landingpage />
          </Route>
          <Route path="/miners">
            <MingingPage />
          </Route>
          <Route path="/consultation">
            <ConsultationPage />
          </Route>
          <Route path="/">
            <Landingpage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
