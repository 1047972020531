import React, { Suspense, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  FaBeer,
  FaChartLine,
  FaServer,
  FaTelegramPlane,
  FaMagic,
  FaLocationArrow,
  FaSatellite,
  FaClock,
  FaAddressCard,
} from "react-icons/fa";
import { GiTakeMyMoney, GiMoneyStack } from "react-icons/gi";
import { MdEmail } from "react-icons/md";

import Accordion from "react-bootstrap/Accordion";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "../component.css";
import "./landingPage.css";
import Footer from "../Footer/Footer";

const LandingPage = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);

  return (
    <>
      <div className="globalFont ">
        <div
          className=" landingContainer fullLength"
          style={{
            backgroundAttachment: "fixed",
            backgroundImage: "url('/background.png')",
          }}
        >
          <div className="headerItem">
            <Row>
              <Col>
                <h1>We are the first</h1>
                <h1>Co-ownership platform</h1>
                <h1>For Bitcoin mining.</h1>
                <h2>
                  Mine cryptocurrency from the comfort of your own home with one
                  of our colocation facilities.
                </h2>
              </Col>
              <Col>
                <img src="/hero.png" />
              </Col>
            </Row>
          </div>
        </div>
        <div id="about" className=" landingContainer">
          <div className=" middleItem">
            <Row>
              <Col
                className="middleItemText"
                lg="6"
                style={{ backgroundColor: "#f6f9ff" }}
              >
                <h2
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  WHO WE ARE?
                </h2>
                <h3>
                  We bring miner hosting into the modern era by offering shares
                  in crypto-currency miners.
                </h3>
                <p>
                  By partnering with larger mining farms we can help everyone
                  benefit from the perks of industrial scale mining while only
                  purchasing shares in a miner as opposed to the initial
                  $10k-$13k investment.
                </p>
              </Col>
              <Col lg="6">
                <img src="/btcMachines.jpeg" />
              </Col>
            </Row>
          </div>
        </div>

        <div id="services" className=" landingContainer">
          <div className="howDoesItWorkItem">
            <h1 className="paddingHeader">How does it work?</h1>
            <Row>
              <Col className="box">
                <h3>Crowdfunding</h3>
                <img src="/crowdFunding.svg" width="300px" height="300px" />
                <p>
                  Previously only wealthy individuals and business angels could
                  invest in high powered bitcoin miners.
                  <br />
                  Our platform helps democratise the investment process by
                  allowing a larger pool of investors to purchase shares in
                  bitcoin miners.
                </p>
              </Col>
              <Col className="box">
                <h3>How To Buy Shares</h3>
                <img src="/invest.svg" width="300px" height="300px" />
                <p>
                  Visit our miners page and purchase anything from your first
                  share to a whole miner.
                  <br />
                  Once each miner is fully funded our team will buy, ship and
                  install the mining hardware for you in one of our certified
                  colocation facilities.
                </p>
              </Col>
              <Col className="box">
                <h3>Earning Bitcoin</h3>
                <img src="/bitcoinHolding.svg" width="300px" height="300px" />
                <p>
                  Each SharedRig customer recieves monthly dividends from their
                  miners to their bitcoin wallet.
                  <br />
                  The monthly payments will be recieved in the form of bitcoin
                  and directly proportional to the shares they own in each
                  miner.
                </p>
              </Col>
            </Row>
          </div>
        </div>

        <div  className=" landingContainer">
          <div className="whyUs ">
            <h1 className="paddingHeader">Why do people choose Shared Rig</h1>
            <Row className="align-self-center">
              <Col md={4} lg={4} xxl={4}>
                <img src="/celebrate.svg" width="300px" height="300px" />
              </Col>
              <Col md={8} lg={8} xxl={8}>
                <Row>
                  <Col className="whyUsBox" lg="3">
                    <FaChartLine size="40" />
                    <div>
                      <h4>Add to your profolio</h4>
                      <p>
                        {" "}
                        Invest in miners to earn bitcoin as opposed to just
                        buying the currency.
                      </p>
                    </div>
                  </Col>
                  <Col className="whyUsBox" lg="3">
                    <FaServer size="40" />
                    <div>
                      <h4>Competitive rates</h4>
                      <p>
                        {" "}
                        Our rates allows users to benefit from up to 50% cheaper
                        running costs than setting a miner up at home.
                      </p>
                    </div>
                  </Col>
                  <Col className="whyUsBox" lg="3">
                    <GiMoneyStack size="40" />
                    <div>
                      <h4>Monthly dividends</h4>
                      <p>
                        {" "}
                        Our customers will recieve monthly earnings from their
                        miners straight to their bitcoin wallet.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="whyUsBox" lg="3">
                    <FaLocationArrow size="40" />
                    <div>
                      <h4>Colocation facilities</h4>
                      <p>
                        {" "}
                        Users have all the benefits of mining without any set up
                        or maintenance.
                      </p>
                    </div>
                  </Col>
                  <Col className="whyUsBox" lg="3">
                    <FaMagic size="40" />
                    <div>
                      <h4>Easy Setup</h4>
                      <p>
                        {" "}
                        We eliminate all the users setup of the miners and
                        handle everything from purchase to installation.
                      </p>
                    </div>
                  </Col>
                  <Col className="whyUsBox" lg="3">
                    <FaSatellite size="40" />
                    <div>
                      <h4 >Remote monitoring</h4>
                      <p>
                        {" "}
                        Use our platform to monitor your miners remotely and
                        leave our team handle the rest.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg="3">
              <FaBeer />
              <div>
                <h4>Add to your profolio</h4>
                <p>
                  {" "}
                  ante hendrerit, condimentum lorem at, commodo ex. Vivamus id
                  fringilla tortor. Proin finibus lacus nisi.
                </p>
              </div>
            </Col> */}
            </Row>
          </div>
        </div>

        <div id="faq" className=" landingContainer">
          <div className="faq">
            <h1 className="paddingHeader" >Frequently Asked Questions</h1>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How do I choose a miner?</Accordion.Header>
                <Accordion.Body>
                  Machines with high hashrate and lower power consumption are
                  best. Bitmain (Antminer), MicroBT (WhatsMiner), and Canaan
                  (AvalonMiner) are the most well-known bitcoin ASIC
                  manufacturers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What payment methods are available?
                </Accordion.Header>
                <Accordion.Body>
                  Mineology processes fiat payments through Stripe. Customers
                  can pay via debit, credit or bank wire. Please note a 2.9% fee
                  is included on credit card purchases. Mineology also offers
                  customers the ability to pay in Bitcoin (BTC), Tether (USDT)
                  or USD Coin (USDC).
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How do I calculate the miners return?
                </Accordion.Header>
                <Accordion.Body>
                  Using a miners hashrate and powerconsumption we can calculate
                  how much each bitcoin should mine a month. Several websites
                  such as Nice Hash are easy ways to calculate mining
                  profitability.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How do I get paid?</Accordion.Header>
                <Accordion.Body>
                  The dividends of each miner are paid directly into each
                  shareholders bitcoin wallet. A bitcoin wallet can be easily
                  set up by following this tutorial.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Do I actually own the miner?
                </Accordion.Header>
                <Accordion.Body>
                  Each customer fully owns their shares in any miner they invest
                  in. If you invest in 10% of a miner, you earn 10% of the
                  machines monthly bitcoin mined
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Is there any setup involved?
                </Accordion.Header>
                <Accordion.Body>
                  SharedRig will handle all set up including, purchase of the
                  miner and shipping to one of our collocation facilities,
                  meaning it has never been easier for you start mining
                  cryptocurrency.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div id="contact" className=" landingContainer">
          <div className="contactUs">
            <h1 className="paddingHeader">Contact Us</h1>
            <Row>
              <Col>
                <Row>
                  <Col className="contactBox">
                    <FaAddressCard size="40" />
                    <h4>Address</h4>
                    <p>
                       Sugar Land <br /> Texas USA{" "}
                    </p>
                  </Col>
                  <Col className="contactBox">
                    <FaClock size="40" />
                    <h4>Working Hours</h4>
                    <p>
                      Monday - Friday <br />
                      9:00am-9:00pm
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="contactBox">
                    <FaTelegramPlane size="40" />
                    <h4>Telegram</h4>
                    <p>@sharedRig</p>
                  </Col>
                  <Col className="contactBox">
                    <MdEmail size="40" />
                    <h4>Email Us</h4>
                    <p>support@sharedrig.com</p>
                  </Col>
                </Row>
              </Col>
              <Col className="contactForm">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col className="formInput" lg="6">
                      {/* register your input into the hook by invoking the "register" function */}
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        {...register("name")}
                      />
                    </Col>
                    <Col className="formInput" lg="6">
                      {/* include validation with required or other standard HTML validation rules */}
                      {/* <input {...register("email", { required: true })} /> */}
                      <Form.Control
                        type="email"
                        placeholder=" Email"
                        {...register("name")}
                      />
                    </Col>
                    <Col className="formInput" lg="12">
                      {/* include validation with required or other standard HTML validation rules */}
                      {/* <input {...register("message", { required: true })} /> */}
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        {...register("name")}
                      />
                    </Col>

                    <Col className="formInput" lg="12">
                      {/* include validation with required or other standard HTML validation rules */}
                      {/* <input {...register("message", { required: true })} /> */}
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "100px" }}
                      />
                    </Col>

                    {/* errors will return when field validation fails  */}
                    {errors.exampleRequired && (
                      <span>This field is required</span>
                    )}
                    <div >
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
